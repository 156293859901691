// Spacing
@for $i from 1 through 10 {
	.mt-#{$i} {
		margin-top: #{$i}rem !important;
	}
}

@for $i from 1 through 10 {
	.mb-#{$i} {
		margin-bottom: #{$i}rem !important;
	}
}

@include mq(sm) {
	@for $i from 0 through 10 {
		.mt-sm-#{$i} {
			margin-top: #{$i}rem !important;
		}
	}

	@for $i from 0 through 10 {
		.mb-sm-#{$i} {
			margin-bottom: #{$i}rem !important;
		}
	}
}


@include mq(md) {
	@for $i from 0 through 10 {
		.mt-md-#{$i} {
			margin-top: #{$i}rem !important;
		}
	}

	@for $i from 0 through 10 {
		.mb-md-#{$i} {
			margin-bottom: #{$i}rem !important;
		}
	}
}

@include mq(lg) {
	@for $i from 0 through 10 {
		.mt-lg-#{$i} {
			margin-top: #{$i}rem !important;
		}
	}

	@for $i from 0 through 10 {
		.mb-lg-#{$i} {
			margin-bottom: #{$i}rem !important;
		}
	}
}

@include mq(xl) {
	@for $i from 0 through 10 {
		.mt-xl-#{$i} {
			margin-top: #{$i}rem !important;
		}
	}

	@for $i from 0 through 10 {
		.mb-xl-#{$i} {
			margin-bottom: #{$i}rem !important;
		}
	}
}

@include mq(xxl) {
	@for $i from 0 through 10 {
		.mt-xxl-#{$i} {
			margin-top: #{$i}rem !important;
		}
	}

	@for $i from 0 through 10 {
		.mb-xxl-#{$i} {
			margin-bottom: #{$i}rem !important;
		}
	}
}

.m-b-1 {
	margin-bottom: .4rem !important;
}

.m-b-2 {
	margin-bottom: .8rem !important;
}

.m-b-3 {
	margin-bottom: 1.6rem !important;
}

.m-b-4 {
	margin-bottom: 2.4rem !important;
}

.m-b-5 {
	margin-bottom: 4.8rem !important;
}