// About
.about {
	.feature-box {
		 h3 {
			margin-bottom: 1.2rem;
			text-transform: none;
			font-weight: 600;
			font-size: 18px;
			line-height: 20px;
			color: #21293c;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
		
		i {
			margin-bottom: 1.3rem;
			font-size: 5.5rem;
		}

		p {
			line-height: 27px;
		}
	}
}

.page-header-bg {
	padding: 8.3rem 0 8.7rem;

	h1 {
		color: #1e3636;
		font-size: 3.6rem;
		font-weight: 900;
	    margin-bottom: 2.4rem;
		font-family: $font-family;
		letter-spacing: 0;
	
		span {
			display: block;
			color:#1e3636;
			font-size: .5em;
			font-weight:700;
			line-height: 1.2;
			font-family: $second-font-family;
			letter-spacing: 0;
		}
	}

	.btn {
		padding: 11px 29px;
		font-family: $third-font-family;
		font-weight: inherit;
		font-size: inherit;
		letter-spacing: 0;
		z-index: 3;
	}
}

.subtitle {
	color: #21293c;
	font-size: 1.9rem;
}

.about-section {
	padding: 1rem 0 2.5rem;
	color: #7b858a;
	font-size: 1.5rem;
	line-height: 1.8;

	.subtitle {
		margin-bottom: 1.7rem;
	}

	p {
		margin-bottom: 2rem;
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
	}

	.lead {
		font-family: $second-font-family;
		color: #21293c;
		font-size: 1.8rem;
		line-height: 1.5;
		font-weight: 400;
	}
}

.features-section {
    padding: 5.1rem 0 2rem;
	
	.subtitle {
		margin-bottom: 1.7rem;
	}

	h3 {
		font-family: $second-font-family;
	}

	.feature-box {
		padding: 3rem 4rem;
	}
}

.testimonials-section {
	padding: 5.1rem 0 7rem;

	.subtitle {
		margin-bottom: 5.2rem;
	}
}

.testimonials-carousel {
	blockquote {
		margin-bottom: 0;
	}

	&.owl-theme .owl-nav.disabled + .owl-dots {
		margin-top: .5rem;
	}
}

.testimonial-title {
	display: block;
    margin-bottom: 2px;
	font-size: 1.6rem;
	text-transform: uppercase;
	color: #2b2b2d;
}

.counters-section {
	padding: 5rem 0 2.4rem;
}

.count-container {
	.count-wrapper {
		color: #0087cb;
		font-size: 3.2rem;
		font-weight: 800;
		line-height: 3.2rem;
		font-family: $font-family;
	}

	span:not(.count-to) {
		font-size: 1.9rem;
	}

	.count-title {
		color: #7b858a;
		font-size: 1.4rem;
		font-weight: 600;
	}
}

.team-info {
	figure {
		position: relative;
	}

	&:hover {
		.prod-full-screen {
			opacity: 1;
		}
	}

	.prod-full-screen {
		display: flex;
		width: 30px;
		height: 30px;
		align-items: center;
		justify-content: center;
		background-color: $primary-color-dark;
		border-radius: 50%;
		bottom: 5px;
		right: 5px;

		i {
			color: #fff;
		}
	}
}

.owl-carousel.images-left img {
	width: auto;
}

@include mq(lg) {
	.counters-section .col-md-4 {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}
}

@include mq(md) {
	.about-section {
		padding-top: 3.1rem;
		padding-bottom: 4.5rem;
	}
}

@include mq(sm) {
	.testimonial {
		blockquote {
			margin-left: 85px;
			padding: 2rem 3rem 1.5rem 2rem;
		}
	}
}