@keyframes maskUp {
    from {
        transform: translate(0,100%)
    }

    to {
        transform: translate(0,0)
    }
}

@keyframes maskRight {
    from {
        transform: translate(-100%,0)
    }
    to {
        transform: translate(0,0)
    }
}

@keyframes maskDown{
    from {
        transform: translate(0,-100%)
    }
    to {
        transform: translate(0,0)
    }
}

@keyframes maskLeft{
    from {
        transform: translate(100%,0)
    }
    to {
        transform: translate(0,0)
    }
}

.maskUp{
    animation-name: maskUp
}

.maskRight{
    animation-name: maskRight
}

.maskDown{
    animation-name: maskDown
}

.maskLeft{
    animation-name: maskLeft
}

@keyframes fadeInUpShorter {
    from {
        opacity: 0;
        transform:translate(0,50px)
    }
    to {
        opacity:1;
        transform:none
    }
}
.fadeInUpShorter {
    animation-timing-function: ease-out;
    animation-name: fadeInUpShorter
}

@keyframes fadeInLeftShorter {
    from {
        opacity: 0;
        transform: translate(50px,0)
    }
    to {
        opacity: 1;
        transform: none
    }
}

.fadeInLeftShorter {
    animation-timing-function: ease-out;
    animation-name: fadeInLeftShorter
}

@keyframes fadeInRightShorter {
    from {
        opacity: 0;
        transform: translate(-50px,0)
    }

    to {
        opacity: 1;
        transform: none
    }
}

.fadeIn {
    animation-name: fadeIn
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


.fadeInRightShorter {
    animation-timing-function: ease-out;
    animation-name: fadeInRightShorter
}

@keyframes fadeInDownShorter {
    from {
        opacity: 0;
        transform: translate(0,-50px)
    }

    to {
        opacity: 1;
        transform: none
    }
}

.fadeInDownShorter{
    animation-name: fadeInDownShorter
}

@keyframes blurIn {
    from {
        opacity: 0;
        filter: blur(20px);
        transform: scale(1.3)
    }
    to {
        opacity: 1;
        filter: blur(0);
        transform: none 
    }
}

.blurIn {
    animation-name: blurIn
}

@keyframes dotPulse {
    from {
        opacity:1;
        transform:scale(.2)
    }

    to {
        opacity:0;
        transform:scale(1)
    }
}
.dotPulse {
    animation-name: dotPulse;
    animation-iteration-count: infinite;
    animation-duration: 4s
}


@keyframes slideInUp {
    0% {
        transform: translate3d(0, 100%, 0);
        visibility: visible
    }

    to {
        transform: translateZ(0)
    }
}

@keyframes slideInDown {
    0% {
        transform: translate3d(0, -100%, 0);
        visibility: visible
    }

    to {
        transform: translateZ(0)
    }
}

@keyframes slideInLeft {
    0% {
        transform: translate3d(-100%, 0, 0);
        visibility: visible
    }

    to {
        transform: translateZ(0)
    }
}

@keyframes slideInRight {
    0% {
        transform: translate3d(100%, 0, 0);
        visibility: visible
    }

    to {
        transform: translateZ(0)
    }
}

@keyframes flipInX {
    0% {
        animation-timing-function: ease-in;
        opacity: 0;
        transform: perspective(400px) rotateX(90deg)
    }

    to {
        transform: perspective(400px)
    }
}

@keyframes flipInY {
    0% {
        animation-timing-function: ease-in;
        opacity: 0;
        transform: perspective(400px) rotateY(-90deg)
    }

    to {
        transform: perspective(400px)
    }
}

@keyframes brightIn {
    0% {
        animation-timing-function: ease-in;
        filter: brightness(0%)
    }

    to {
        filter: brightness(100%)
    }
}

@keyframes bounceInLeft {
    0%,60%,75%,90%,to {
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        opacity: 0;
        transform: translate3d(-3000px,0,0)
    }

    60% {
        opacity: 1;
        transform: translate3d(25px,0,0)
    }

    75% {
        transform: translate3d(-10px,0,0)
    }

    90% {
        transform: translate3d(5px,0,0)
    }

    to {
        transform: translateZ(0)
    }
}

.bounceInLeft {
    animation-name: bounceInLeft
}

@keyframes rotateInUpLeft {
    0% {
        opacity: 0;
        transform: rotate(90deg);
        transform-origin: left bottom
    }

    to {
        opacity: 1;
        transform: translateZ(0);
        transform-origin: left bottom
    }
}

.rotateInUpLeft {
    animation-name: rotateInUpLeft
}

.brightIn {
    animation-name: brightIn
}

@keyframes customSVGLineAnimTwo {
    from {
        stroke-dasharray: 820;
        stroke-dashoffset: 500
    }

    to {
        stroke-dasharray: 1120;
        stroke-dashoffset: 500
    }
}

.customSVGLineAnimTwo {
    animation-name: customSVGLineAnimTwo
}

.appear-animate {
    opacity: 0;
}

.appear-animation-visible {
    opacity: 1;
}

