// Mobile Menu


// # Contents
// 1. Mobile Menu Container
// 2.

// # Settings

// # Variables

$mobile-menu-background-color: #1d1e20;
$mobile-menu-divider-color: #242527;

// 1. Menu Container
.mobile-menu-container {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	max-width: 260px;
	background-color: $mobile-menu-background-color;
	font-size: 1.2rem;
	line-height: 1.5;
    z-index: 2051;
	transform: translateX(-100%);
	transition: transform .25s;
	overflow-y: auto;

	.mmenu-active & {
		transform: translateX(0);
	}

	.social-icons {
		display: flex;
		-ms-flex-pack: center;
		justify-content: center;
		margin-bottom: 0;
	}

	.social-icon + .social-icon {
		margin-left: 1.2rem;
	}

	.search-wrapper {
		position: relative;
		padding-left: 1.5rem;
		padding-right: 1.5rem;

		.form-control {
			background: #282e36;
			border: 0;
			line-height: 22px;
			padding: 8px 12px;
			height: 38px;
		}

		.btn {
			position: absolute;
			top: 10px;
			right: 28px;
		}
	}
}

.mobile-menu-wrapper {
	position: relative;
	padding: 4.7rem 0 3rem;
}

.mobile-menu-close {
	position: absolute;
    top: 1.2rem;
    right: 2.1rem;
    padding: .4rem;
    color: #fff;
    line-height: 1;
    cursor: pointer;
    z-index: 9;
    font-size: 1.3rem;
}

.mobile-menu-overlay {
	display: block;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transition: all .25s;
    background: #000;
	opacity: 0;
	visibility: hidden;
	z-index: 2050;
}

.mmenu-active .mobile-menu-overlay {
	opacity: .35;
	visibility: visible;
}

.mmenu-active .sidebar-product {
	display: none;
}

.mmenu-active .mobile-sidebar {
	display: none;
}

.mobile-nav {
	margin: 0 0 2rem;
	padding: 0;
}

.mobile-menu {
	margin: 0;
	padding: 0;
	list-style: none;

	> li {
		> a {text-transform: uppercase;}
	}

	li {
		display: block;
		position: relative;
		overflow: hidden;

		&:not(:last-child) {
			border-bottom: 1px solid $mobile-menu-divider-color;
		}

		a {
			display: block;
			position: relative;
			margin-left: 1.1rem;
		    margin-right: 1.1rem;
			padding: 1rem 0 1.1rem .7rem;
			color: #fff;
			font-size: 1.3rem;

			&:hover,
			&:focus {
				color: #fff;
				text-decoration: none;
			}

			&.collapsing {
				height: auto;
			}
		}

		&.open,
		&.active {
			> a {
				color: #fff;
				background-color: #282e36;
			}
		}

		> div {
			padding-left: 1rem;
		}

		ul {
			margin: 0;
			padding: 0;

			li a {
				padding-left: 2.5rem;
			}

			ul {
				li a {
					padding-left: 3.5rem;
				}
			}
		}
	}
}

.mmenu-btn {
	display: block;
    position: absolute;
    top: 46%;
    right: .5rem;
    width: 3rem;
    height: 3rem;
    margin-top: -1.5rem;
    text-align: center;
    border-radius: 0;
    outline: none;
    font-weight: bold;
    background-color: transparent;
    color: #fff;
    font-size: 1.7rem;
    line-height: 3rem;
    cursor: pointer;

	&:after {
		display: inline-block;
		margin-top: -2px;
		font-family: 'porto';
		content: '\e81c';
	}

	.expanded > &:after {
		content: '\e81b';
	}
}
