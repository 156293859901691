// Feature Box

@include set-default(
	(
		feature-box: (
			title: (
				color: false,
				font-size: 1.6rem,
				font-weight: 700
			)
		)
	)
);

.feature-box {
	color: #7b858a;
	font-size: 1.5rem;
	line-height: 2;
	margin-bottom: 4rem;

	i {
		display: inline-block;
		margin-bottom: 2.2rem;
		color: $primary-color;
		font-size: 5rem;
		line-height: 1;

		&:before {
			margin: 0;
		}
	}

	h3 {
		margin-bottom: 2rem;
		@include css( color, feature-box, title, color );
		@include css( font-size, feature-box, title, font-size );
		@include css( font-weight, feature-box, title, font-weight );
		text-transform: uppercase;
		line-height: 1.1;
		letter-spacing: 0;
	}

	p {
		margin-bottom: 0;
	}

	&.border-top-primary {
		border-bottom: 1px solid #dfdfdf;
		border-left: 1px solid #ececec;
		border-right: 1px solid #ececec;
		box-shadow: 0 2px 4px 0px rgba(0,0,0,0.05);

		.feature-box-content {
			border-top: 4px solid $primary-color;
			padding: 30px 20px 10px 20px;
		}
	}
}

.feature-box-content {
	color: #7b858a;
	font-size: 1.5rem;
	line-height: 1.9;
	padding-left: 10px;
	padding-right: 10px;
}

.feature-rounded i {
    width: 80px;
    height: 80px;
    border-radius: 500px;
    font-size: 35px;
    line-height: 80px;
}