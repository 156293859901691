// Testimonials

.testimonial-owner {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-content: center;

	figure {
		max-width: 40px;
		margin-right: 25px;
		margin-bottom: 2rem;
	}

	h4 {
		display: block;
		margin-bottom: .5rem;
		padding-top: .7rem;
		color: #111;
		font-size: 1.4rem;
		text-transform: uppercase;
	}

	span {
		display: block;
		color: #666;
		font-size: 1.2rem;
		text-transform: uppercase;
		letter-spacing: .045em;
		line-height: 1.2;
		font-weight: 600;
	}
}

.testimonial {
	blockquote {
		position: relative;
		margin: 0 0 0 15px;
		padding: 1rem 2rem;

		&:before {
			position: absolute;
			top: 0;
			left: -0.4em;
			color: $primary-color;
			font-family: 'Playfair Display';
			font-size: 5rem;
			font-weight: 900;
			line-height: 1;
			content: '\201C';
		}

		p {
		    margin-bottom: 0;
			font-family: inherit;
			font-style: normal;
			font-size: 14px;
			line-height: 24px;
			color: #62615e;
		}
	}
}

@media(max-width: 576px) {
	.testimonial blockquote:before {
		left: -7px;
	}

	.testimonial blockquote {
		padding-left: 3rem;
	}
}
