// Product Full Width Page

// # content
// 1. Product Full Width Base
// 2. Product Full Width Details
// 3. Product Full Width Single Tab
// 4. Responsive

// 1. Product Full Width Base
.product-full-width {
	margin-bottom: .7rem;

	.product-single-share:not(.icon-with-color) .social-icon:not(:hover):not(:active):not(:focus) {
		background-color: #fff;
	}
	
	.single-product-custom-block {
		.porto-block {
			padding-right: 7rem;
		}
	}

	.product-single-details {
		margin-bottom: 4.1rem;
	}

	.product-title {
		line-height: 1.1;
	}
	.single-info-list {
		margin-bottom: -4px;
	}

	.product-filters-container {
		padding-bottom: .7rem;
	}

	.product-single-filter {
		img {
			width: 30px;
			height: 30px;
		}

		label {
			margin-bottom: 0;
			margin-right: -1px;
		}
	} 

//  2.6) Product Single Share
	.custom-product-single-share {
		right: -2.2rem;

		&:not(.icon-with-color) .social-icon {
            border-radius: 0;
        }
	}

// 3. Product Full Width Single Tab
	.product-single-tabs .tab-pane {
		padding-bottom: .7rem;
	}

	.product-desc-content {
		margin-bottom: 1.5rem;

		ul {
			margin-top: 3.7rem;
			margin-bottom: 4.4rem;
			padding-left: 5rem;
		}

		li::before {
			left: 1.6rem;
		}
	}
}

// 4. Responsive
@media (max-width: 1400px) and (min-width: 576px) {
	.product-full-width {
		.product-desc-content {
			.col-xl-4 {
				-ms-flex: 0 0 50%;
				flex: 0 0 50%;
				max-width: 50%;
			}
		}
	}
}

@media (max-width: 1400px)and (min-width: 992px)  {
	.product-full-width {
		.product-size-content {
			.col-md-8,
			.col-md-4 {
				flex: 0 0 100%;
				max-width: 100%;
			}
		}

		.table.table-size {
			thead tr th, 
			tbody tr td {
				padding-right: 3.2rem;
				padding-left: 2rem;
			}
		}
	}
}


@media (min-width: 992px) {
	.product-full-width .product-nav {
		right: 2rem;
	}
}

@include mq(lg, max) {
	.product-full-width {
		.custom-product-single-share {
			right: 1rem;
		}

		.single-product-custom-block {
			margin-bottom: 2rem;
		}

		.social-icons {
			position: fixed;
			right: 2rem;
			top: 48.4%;
			z-index: 10;
			transform: translateY(-50%);
		}
	}
}

@media (max-width: 767px) {
	.product-full-width .social-icons {
		right: 2.3rem;
	}
}

@media (max-width: 480px) {
	.transparent-dots {
		top: 5px;
		left: 7px;
	}

	.transparent-dots .owl-dot {
		max-width: 63px;
		margin-bottom: 0;
	}
}