/* -------------------------------------------
    Toast
---------------------------------------------- */
.Toastify {
    position: relative;
    z-index: 10001;

    button.mfp-close {
        right: 0;
    }
}

.Toastify__toast-body {
    padding: 0;
}

.toast-container.Toastify__toast-container {
    width: auto;

    .Toastify__toast {
        padding: 0;
    }

    .minipopup-box {
        padding: 2rem;
    }

    .product-media::before {
        padding-top: 100%;
    }

    .product.product-cart {
        margin-bottom: 0;
    }
}

.Toastify__toast--success.Toastify__toast {
    border-radius: 5px;
    max-width: 273px;

    .Toastify__toast-body {
        margin: auto 3rem;
        font-size: 1.5rem;
        width: 213px;
    }
}