.tooltip {
	font-family: $font-family;
	font-size: 1.3rem;

	&.show {
		opacity: 1;
	}

	.arrow {
		width: 1rem;
		height: 1rem;
	}
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^='top'] {
	padding: 1rem 0;
}

.bs-tooltip-top .arrow:before,
.bs-tooltip-auto[x-placement^='top'] .arrow:before {
	margin-left: -.5rem;
	border-width: 1rem 1rem 0;
	border-top-color: #ddd;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^='right'] {
	padding: 0 1rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^='right'] .arrow {
	width: 1rem;
	height: 2rem;
}

.bs-tooltip-right .arrow:before,
.bs-tooltip-auto[x-placement^='right'] .arrow:before {
	border-width: 1rem 1rem 1rem 0;
	border-right-color: #ddd;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^='bottom'] {
	padding: 1rem 0;
}

.bs-tooltip-bottom .arrow:before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow:before {
	margin-left: -.5rem;
	border-width: 0 1rem 1em;
	border-bottom-color: #ddd;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^='left'] {
	padding: 0 1rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^='left'] .arrow {
	width: 1rem;
	height: 1rem;
}

.bs-tooltip-left .arrow:before,
.bs-tooltip-auto[x-placement^='left'] .arrow:before {
	border-width: 1rem 0 1rem 1rem;
	border-left-color: #ddd;
}

.tooltip-inner {
	max-width: 270px;
	padding: 1.2rem 1.5rem;
	border: 1px solid #ddd;
	border-radius: .1rem;
	background-color: #f4f4f4;
	color: #777;
	text-align: left;
}
