// Cart
.cart-table-container {
	margin-bottom: 2.5rem;

	.input-group .form-control {
		height: 43px;
		border-color: rgba(0,0,0,0.09);
	}

	.btn-shop,
	.btn-sm {
		border: none;
		background-color: #f4f4f4;
		color: #222529;

		&:hover {
			color: #fff;
			background-color: $primary-color;
		}
	}
}

.table.table-cart, .table.table-wishlist {
	tr {
		th,
		td {
			vertical-align: middle;
		}

		th {
			border: 0;
			color: #222529;
			font-weight: 700;
			line-height: 2.4rem;
			text-transform: uppercase;
		}

		td {
			border-top: 1px solid #e7e7e7;

			&.product-col {
				padding:  2rem .8rem 1.8rem 0;
			}
		}

		&.product-action-row {
			td {
				padding: 0 0 2.2rem;
				border: 0;
			}
		}
	}

	.product-image-container {
		position: relative;
		width: 8rem;
		margin: 0;
	}

	.product-title {
		margin-bottom: 0;
		padding: 0;
		font-family: $font-family;
		font-weight: 400;
		line-height: 1.75;

		&:hover {
			color: $primary-color;
		}

		a {
			color: inherit;
		}
	}

	.product-single-qty {
		margin: .5rem 4px .5rem 1px;

		.form-control {
			height: 48px;
			width: 44px;
			font-size: 1.6rem;
			font-weight: 700;
		}
	}

	.subtotal-price {
		color: #222529;
		font-size: 1.6rem;
		font-weight: 600;
	}

	.btn-remove {
		right: -10px;
		font-size: 1.1rem;
	}

	tfoot {
		td {
			padding: 2rem .8rem 1rem;
		}

		.btn {
		    padding: 1.2rem 2.4rem 1.3rem 2.5rem;
			font-family: $font-family;
			font-size: 1.3rem;
			font-weight: 700;
			height: 43px;
			letter-spacing: -.018em;

			& + .btn {
				margin-left: 1rem;
			}
		}
	}

	.bootstrap-touchspin.input-group {
		margin-right: auto;
		margin-left: auto;
	}
}

.table.table-cart {
	.product-title a,
	.subtotal-price {
		display: block;
		margin-bottom: 1px;
	}

	.product-price {
		font-size: 1.4rem;
		font-weight: 400;
	}
} 

.table-cart {
	tr {
		th {
			padding: 1rem;

			&.thumbnail-col {
				width: 16%;
			}

			&.product-col {
				width: 33%;
			}

			&.price-col {
				width: 14%;
			}
		}
	}

	td {
		padding: 2rem 1rem;
	}
}

i.cart-empty {
	font-size: 100px;
    color: #d3d3d4;
}

.btn-remove,
.btn-edit,
.btn-move {
	&:hover,
	&:focus {
		color: $primary-color;
		text-decoration: none;
	}
}

.btn-edit {
	margin-right: 1rem;
	font-size: 1.3rem;
}

.btn-move {
	font-size: 1.3rem;
	line-height: 2.5rem;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.qty-col {
	min-width: 98px;
}

.product-col {
	tbody & {
		font-size: 0;
	}

	.product-image-container {
		display: table-cell;
		padding-right: 1.8rem;
		margin-bottom: 0;
		vertical-align: middle;
	}

	.product-image img {
		border: 1px solid #ccc;
	}

	.product-title {
		margin-bottom: 1px;
		display: table-cell;
		vertical-align: middle;
	}
}

.cart-discount {
	margin-bottom: 4rem;

	h4 {
		margin-bottom: 1.2rem;
		font-size: 1.6rem;
		font-weight: 400;
	}

	form {
		max-width: 420px;
	}

	.input-group-append {
		margin-left: 3px;
	}
}

.cart-summary {
	margin-bottom: 3.6rem;
    padding: 2.4rem 3rem 3.4rem;
	border: 2px solid #e7e7e7;
	background: #fff;

	h3 {
		margin-bottom: 2.3rem;
		font-size: 1.6rem;
		letter-spacing: -.01em;
	}
}

.checkout-progress-bar li a.active, .checkout-progress-bar li a:hover {
	color: $primary-color;
	opacity: 1;
}

.table.table-totals {
	margin-bottom: 2.6rem;

	.custom-radio .custom-control-input {
		width: 100px;
	}

	h4 {
		display: inline;
		margin-bottom: 0;
		font-size: 1.4rem;
		font-weight: 600;
		letter-spacing: -.01em;
	}
	
	.form-control {
		line-height: 1.5;
	}

	.form-group {
		margin-bottom: 1.5rem;
	}

    .select2-container.select2-container--default {
        .select2-selection--single {
            min-height: 37px;
        }
    
        .select2-selection--single .select2-selection__rendered {
            line-height : 1.6;
        }
    }

	tr {
		th,
		td {
			padding: 1rem;
			border: 0;
			letter-spacing: -.01em;
			font-size: 14px;
			font-weight: 600;
			color: #222529;
			font-family: "poppins";

			&:last-child {
				color: #777;
				text-align: right;
				font-weight: 400;
			}
		}
	}

	tbody tr {
		border-bottom: 1px solid #dcdcdc;
	}

	tfoot {
		border-top: 1px solid #ccc;
		color: $headings-text;
		font-size: 1.6rem;
		font-weight: 700;

		tr td {
			padding-top: 1.3rem;
			padding-bottom: 1.6rem;
			vertical-align: middle;
			font-size: 1.6rem;
			padding-left: 1.2rem;
		}

		tr td:last-child {
			font-weight: 700;
			font-size: 22px;
			color: #222529;
		}
		
		b {
			font-size: 2.2rem;
		}
	}
}

.checkout-methods {
	.btn {
		font-family: $font-family;
		font-size: 1.5rem;
		letter-spacing: -.015em;
		padding: 1em 0;

		i {
			margin-left: 1.5rem;
		}
	}
}

.return-to-shop .btn {
	padding: 1.6rem 3.6rem;
	font-family: $font-family;
	font-size: 1.5rem;
	letter-spacing: -.02em;
}

.cart-summary {
	form {
		margin-bottom: 1.6rem;
	}
	
	.form-group-sm {
		max-width: none;
		
		label {
			margin-top: 1.3rem;
			letter-spacing: 0;
			margin-bottom: 1.4rem;
			font-weight: 400;
			font-size: 1.4rem;
			color: #777;
			font-family: $font-family;
		}

		.form-control {
			height: 3.7rem;
			font-size: 1.4rem;
			letter-spacing: -.012em;
		}
	}

	.btn-update-total {
		background-color: #f4f4f4;
		color: #222529;
		border: none;
		padding: 8px 12px;
		line-height: 1.5;
		font-family: $font-family;

		&:hover {
			color: #fff;
			background-color: $primary-color;
		}
	}
}

.cart-empty-page {
	margin-bottom: 5rem;

	i {
		display: block;
		margin-bottom: 1rem;
		font-size: 100px;
		line-height: 0;
		color: #d3d3d4;
	}

	p {
		margin-bottom: 3.1rem;
		color: #777;
	}

	.btn-shop {
		padding: 1.6rem 0;
		padding-bottom: 1.5rem;
		min-width: 200px;
		font-size: 15px;
		letter-spacing: -.015em;
		text-align: center;
	}
}

@include mq(sm, max) {
	.cart-table-container {
		border-top: 4px solid $primary-color;
	}

	.table.table-cart {
		border: 1px solid #e7e7e7;
		border-top: 0;
		box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);

		&,
		tbody,
		tfoot {
			display: block;
		}

		thead {
			display: none;
		}

		tr {
			td {
				padding: .5rem 1rem;
				border-top: 0;

				&.product-col {
					padding-bottom: .5rem;
				}
			}
		}

		.product-row {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-direction: column;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding-top: 3rem;
			padding-bottom: 1.5rem;
			border-top: 1px solid #ddd;

			&:first-child {
				border-top: 0;
			}
		}

		.product-col {
			-ms-flex-direction: column;
			flex-direction: column;
			-ms-flex-pack: center;
			justify-content: center;
			text-align: center;

			.product-image-container {
				-ms-flex: 0 0 auto;
				flex: 0 0 auto;
				margin-right: 0;
				margin-bottom: 1rem;
			}
		}

		tfoot {
			border-top: 1px solid #ddd;

			tr {
				display: block;
				width: 100%;

				td {
					display: -ms-flexbox;
					display: flex;
					-ms-flex-direction: column;
					flex-direction: column;
					-ms-flex-align: start;
					align-items: center;
					padding: 3rem 0;
				}
			}
		}

		.cart-discount {
			margin-bottom: 2rem;

			form {
				margin-bottom: 0;
			}
		}
	}
}

@include mq(xl) {
	.cart-table-container .input-group .form-control {
		width: 183px;
	}
}

@include mq(md, max) {
	.cart-table-container .cart-discount .form-control {
		max-width: 132px;
		margin-right: .4rem;
	}

	.table.table-cart .cart-discount {
		margin-bottom: 1.5rem;
	}
}

@include mq(sm, max) {
	.cart-empty-page {
		padding-top: 4rem;
	}
}

@include mq(xs, max) {
	.cart-summary {
		padding-left: 2rem;
		padding-right: 2rem;
	}
	.table.table-cart .cart-discount {
		margin-bottom: 1.5rem;
	}

	.cart-discount {
		.input-group {
			flex-direction: column;
			align-items: center;
		}
	}

	.cart-table-container .cart-discount .form-control {
		margin: auto;
		margin-bottom: 1.5rem;
		max-width: none;
		width: 100%;
	}
}