// My orders
.order-table-container,
.order-detail-container,
.download-table-container {

    .btn {
        padding: 8px 12px;
        font-size: 14px;
        font-weight: 400;
    }
}

.custom-account-container {
    margin-bottom: 5.6rem;
}

.download-table-container {
    padding-top: 3px;
    
    p {
        margin-bottom: 3.9rem;
    }

	.btn {
	    padding: 9px 24px 8px;
		background-color: #f4f4f4;
		color: #222529;
		border: none;
        font-weight: 700;
        font-family: $font-family;

        &:hover {
            color: #fff;
            background-color: $primary-color;
        }
	}
}

.order-table-container {
    .btn-dark {
        min-width: 200px;
        padding: 16px 0 15px;
        font-size: 15px;
        letter-spacing: -.015em;
        text-align: center;
        font-family: $font-family;
        font-weight: 700;
    }
}

.account-sub-title {
    margin-top: .6rem;
    margin-bottom: 1.5rem;
    font-size: 2.2rem;
    letter-spacing: -.01em;

    i {
        font-size: 3.5rem;
        color: #d3d3d4;
    }
}

.table.table-order,
.table.table-downloads {
    margin-bottom: 1px;
    font-size: 14px;

    thead th {
        border-top: none;
        border-bottom-width: 1px;
        padding: 1.3rem 1rem;
        font-weight: 700;
        color: #222524;
    }

    tbody td {
        vertical-align: middle;
    }
}

.order-old-price, .product-old-count {
    font-size: .9em;
    text-decoration: line-through;
}

.order-new-price {
    box-shadow: 0 1px 0 0 rgba(0,0,0,0.7);
}

.product-new-count {
    text-decoration: underline;
}

.order-info {
    font-size: 1.4rem;

    .order-id, 
    .order-date, 
    .order-status {
        color: #000;
    }
}

.order-detail-container {
    .title {
        font-size: 1.8rem;
        font-weight: 400;
        text-transform: uppercase;
    }
}

.table.table-order-detail {
    th {
        font-weight: 600;
    }

    th, td {
        padding: 1rem;
        font-size: 1.4rem;
        line-height: 24px;
    }

    thead th {
        border: none;
    }
        
    .product-title {
        display: inline;
        color: $primary-color;
        font-size: 1.4rem;
        font-weight: 400;
    }

    .product-count {
        color: $primary-color;
    }
}

.order-update {
    padding: 2rem;
    background-color: #f5f7f7;

    & + & {
        margin-top: 1rem;
    }

    .order-update-time {
        font-weight: 700;
    }

    p {
        margin-bottom: 0;
        font-weight: 300;
    }
}

.order-detail-container address {
    margin-bottom: 2rem;
    line-height: 1.75;

    p:last-child {
        margin-bottom: 0;
    }
}

@include mq(md, max) {
    .table.table-order thead {
        display: none;
    }

    .table.table-order {
        td {
            display: block;
            border-top: none;
            text-align: center;
        }

        .product-thumbnail img {
            display: inline;
        }

        tbody tr {
            position: relative;
            display: block;
            padding: 10px 0;

            &:not(:first-child) {
                border-top: 1px solid #ddd;
            }
        }

        .product-remove {
            position: absolute;
            top: 12px;
            right: 0;
        }
    }
}